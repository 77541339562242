import { Component } from 'react';

export default class Card extends Component {

    render() {
        return (
            <div className="px-4 py-5 bg-gray-200 dark:bg-background/30 shadow-md rounded-lg overflow-hidden sm:p-5">
                {this.props.children}
            </div>
        )
    }
}