import { Component } from 'react';

export default class DashboardLinks extends Component {

    render() {
        return (
            <div className="font-biotif dark:text-gray-300">
                Coming soon... check back later!
            </div>
        )
    }
}