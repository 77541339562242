import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import LelLink from './LelLink';
import moment from 'moment';

import './index.css';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

moment.relativeTimeThreshold('s', 60);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 7);
moment.relativeTimeThreshold('w', 4);
moment.relativeTimeThreshold('M', 12);

root.render(
    <BrowserRouter>
        <LelLink />
    </BrowserRouter>,
);
