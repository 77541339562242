import { useState } from 'react';

import Button from '../ui/Button';
import clsx from 'clsx';

export default function Input({name, value, onChange, color, hide = false}) {
    const [v, setValue] = useState(value);
    const [hidden, setHidden] = useState(hide);
    const [focus, setFocused] = useState(false);
    const [typingTimout, setTypingTimeout] = useState(null);

    return (
        <div>
            {name !== undefined && (
                <div className="flex items-center justify-between pb-2.5">
                    <h5 className="text-[13px] font-medium font-biotif tracking-wide">{name}</h5>
                    {hide && <Button name={!hidden ? "Hide" : "Show"} size="xs" onClick={() => setHidden(!hidden)}/>}
                </div>
            )}
            <input onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onChange={(e) => {
                if(hidden)
                    return;

                clearTimeout(typingTimout);

                const value = e.target.value;

                setValue(value);

                setTypingTimeout(setTimeout(() => {
                    if(value.split(' ').join('') !== "")
                        onChange(value);
                }, 800));
            }} value={hidden ? (v.length === 0 ? "•••••••••" : v.split("").map(() => "•").join("")) : v}
                   type="text"
                   className={clsx(hidden && "tracking-[3px]", "w-full font-biotif font-medium border-t-0 border-r-0 border-l-0 border-b-2 border-dark-300 focus:border-b-lel-100 dark:bg-dark-900 py-1 px-2 focus:outline-none focus:ring-0")}
                   style={{borderColor: focus ? color : "", transition: "border-color 0.2s ease-in-out"}}/>
        </div>
    )
}
