export function fetchJson(url, success, fail, method = 'POST', log = false, port = 8000) {
    fetch(`${url.includes("://") ? url : ((process.env.NODE_ENV !== `production` ? `http://localhost:${port}` : ``) + url)}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then(json => success(json))
        .catch(e => {
            if (e === undefined)
                return;
            if(log)
                console.error(e);

            if(fail !== undefined)
                fail(e)
        });
}

export function fetchBodyJson(url, body, success, fail, log = false, port = 8000) {
    fetch(`${process.env.NODE_ENV !== `production` ? `http://localhost:${port}` : ``}${url}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: body
    })
        .then(response => response.json())
        .then(json => success(json))
        .catch(e => {
            if(log)
                console.error(e);

            if(fail !== undefined)
                fail(e)
        });
}

export function fetchText(url, success, fail, log = false, port = 8000) {
    fetch(`${process.env.NODE_ENV !== `production` ? `http://localhost:${port}` : ``}${url}`, {
        method: 'GET'
    })
        .then(response => response.text())
        .then(text => success(text))
        .catch(e => {
            if(log)
                console.error(e);

            if(fail !== undefined)
                fail(undefined);
        });
}

export function capitalize(s, all = true) {
    return s === undefined || s[0] === undefined ? undefined : (all ? s.split(" ").map((word) => word[0].toUpperCase() + word.substring(1)).join(" ") : s[0].toUpperCase() + s.substring(1));
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


export function getNumberWithOrdinal(n) {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}