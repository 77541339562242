import React, { useState } from 'react';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { fetchJson } from '../../../utils/utils';

import { Redirect } from 'react-router-dom';

import Button from '../../../components/ui/Button';

export default function DashboardSubscriptionSubscribe({location, theme}) {
    // Get the lookup key for the price from the previous page redirect.
    const [clientSecret] = useState(location.clientSecret);
    const [subscriptionId] = useState(location.subscriptionId);
    const [messages, _setMessages] = useState('');
    const [paymentIntent, setPaymentIntent] = useState();
    const [redirect, setRedirect] = useState(undefined);

    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(message);
    }

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();

    if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return '';
    }

    if(location.clientSecret === undefined || location.subscriptionId === undefined)
        return <Redirect to={`/dashboard`}/>;

    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(messages === "Subscription cancelled.")
            return;

        // Get a reference to a mounted CardElement. Elements know how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use card Element to tokenize payment details
        let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            }
        });

        if(error) {
            // show error and collect new card details.
            setMessage(error.message);
            return;
        }
        setPaymentIntent(paymentIntent);
    }

    const handleCancel = async (e) => {
        e.preventDefault();

        // Cancel the subscription.
        fetchJson(`/api/stripe/cancelSubscription/${subscriptionId}`, s => {
            setMessage('Subscription cancelled.');

            setTimeout(() => {
                setRedirect(true);
            }, 3500);
        }, f => {
            console.error(f);
            setMessage('Failed to cancel subscription.');
        })
    }

    if(redirect !== undefined || (paymentIntent && paymentIntent.status === 'succeeded')) {
        return <Redirect to="/dashboard" />
    }

    return (
        <>
            {/*<h1>Subscribe</h1>*/}

            {/*<p>*/}
            {/*    Try the successful test card: <span>4242424242424242</span>.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    Try the test card that requires SCA: <span>4000002500003155</span>.*/}
            {/*</p>*/}

            {/*<p>*/}
            {/*    Use any <i>future</i> expiry date, CVC,5 digit postal code*/}
            {/*</p>*/}

            {/*<hr />*/}

            <form onSubmit={handleSubmit}>
                {location.continue !== undefined && <div className="text-sm font-biotif pb-8">Please continue your purchase.</div>}
                <CardElement options={theme === "dark" ? {style: {base: {color: "white"}}} : {style: {base: {color: "black"}}}}/>

                <div className="mt-8 space-x-3">
                    <Button name="Cancel" size="sm" onClick={handleCancel}/>
                    <Button name="Subscribe" size="sm"/>
                </div>

                <ul className="pt-7 font-biotif text-[15px]">
                    {messages.split("\n").filter(msg => msg !== "").map((msg, i) => (
                        <li key={i}>
                            - {msg}
                        </li>
                    ))}
                </ul>
            </form>
        </>
    )
}
