import { Component } from 'react';

import { fetchBodyJson, fetchJson } from '../../../utils/utils';

import Input from '../../../components/form/Input';
import Notification from '../../../components/ui/Notification';

import CardList from '../../../components/ui/CardList';

export default class DashboardSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            setting: undefined,
            notification: undefined
        }

        this.updateSetting = this.updateSetting.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        fetchJson('/api/auth/setting/get', s => {
            if(this.mounted)
                this.setState({setting: s});
        }, () => {
            if(this.mounted)
                this.setState({notification: <Notification title="Error" type="error" message="Failed to retrieve settings."/>});
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    notification() {
        return this.state.notification || null;
    }

    setting(setting) {
        return this.state.setting === undefined ? "" : this.state.setting[setting];
    }

    updateSetting(name, id, setting) {
        fetchBodyJson('/api/auth/setting/update', JSON.stringify({name: id, value: setting}), s => {
            // this.setting(prevState => ({setting: prevState.settings, [id]: setting}, ...prevState}}));
            this.setState({notification: <Notification message={`${name} updated!`} key={id + "setting"}/>});
        }, f => {
            this.setState({notification: <Notification title="Failed to Save" type="error" message={`Failed to update ${name}.`} key={id + "setting"}/>});
        });
    }

    render() {
        return (
            <div>
                {this.notification()}
                <h5 className="font-medium font-biotif tracking-wide pb-2.5">S3 Information</h5>
                {this.state.setting !== undefined && (
                    <CardList>
                        <Input name="Endpoint" value={this.setting("awsEndpoint")} onChange={(value) => this.updateSetting("AWS Endpoint", "awsEndpoint", value)}/>
                        <Input name="Access Key" value={this.setting("awsAccessKey")} onChange={(value) => this.updateSetting("AWS Access Key", "awsAccessKey", value)} hide={true}/>
                        <Input name="Secret Key" value={this.setting("awsSecretKey")} onChange={(value) => this.updateSetting("AWS Secret Key", "awsSecretKey", value)} hide={true}/>
                    </CardList>
                )}
                <h5 className="font-medium font-biotif tracking-wide pt-6 pb-2.5">Other</h5>
                {this.state.setting !== undefined && (
                    <CardList>
                        <Input name="Embed/Meta Color" color={this.state.setting.color || undefined} value={this.setting("color")} onChange={(value) => this.updateSetting("Embed/Meta Color", "color", value)}/>
                    </CardList>
                )}
            </div>
        )
    }
}