import { Component } from 'react';

import { fetchBodyJson } from '../utils/utils';
import { NavLink, Redirect } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import FormErrors from '../components/form/FormErrors';

const access = {title: "Don't have an account?", name: "Get access", url: "/sign-in/get-access"};
const ids = {
    'sign-in': {
        forgotPass: true,
        footer: access
    },
    'get-access': {
        footer: false,
        custom: {
            title: "Getting Access",
            desc: "Currently, lel.link is invite only, check back soon for updates!"
        }
    },
    'forgot-password': {
        custom: {
            title: "Reset Your Password",
            desc: "Enter your email and we'll send you a link to reset your password."
        },
        footer: access
    },
    'register': {
        custom: {},
        footer: {
            title: "Having trouble?",
            name: "Contact EazyFTW",
            url: "mailto:email@eazyftw.com"
        }
    }
};

export default class SignIn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: ids[this.props.match.params.id || 'sign-in'],
            name: this.props.match.params.id || 'sign-in',

            username: "",
            emailOrUsername: "",

            password: "",
            matchingPassword: "",

            code: "",
            remember: false,
            errors: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.id !== prevProps.match.params.id)
            this.setState({id: ids[this.props.match.params.id || 'sign-in'], name: this.props.match.params.id || 'sign-in'});
    }

    render() {
        if(this.state.id === undefined)
            return <Redirect to="/sign-in"/>;

        return (
            <>
                <Helmet>
                    <title>Sign In - lel.link</title>
                </Helmet>
                <main className="relative z-10 flex-auto flex items-center justify-center text-sm text-gray-600 py-10 sm:py-14 md:py-24 lg:py-32 xl:py-36 px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-sm">
                        <img alt="lel.link Logo" width="40%" className="mx-auto pb-3" src="https://cdn.lel.link/images/small_text.png"/>

                        {this.state.id.custom === undefined ? (
                            <>
                                <div className="relative">
                                    <label htmlFor="email-address" className="sr-only">Email Address</label>
                                    <input id="email-address" name="email" type="email" required="" value={this.state.emailOrUsername} onChange={(e) => this.setState({emailOrUsername: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Email Address"/>
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className="sr-only">Password</label>
                                    <input id="password" name="password" type="password" required="" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Password"/>
                                </div>
                                <div className="flex items-center mb-3">
                                    <input id="remember" name="remember" type="checkbox" onChange={(e) => this.setState({remember: e.target.checked})} className="rounded-md appearance-none checked:bg-lel-100 text-lel-100 checked:border-transparent"/>
                                    <label htmlFor="remember" className="ml-1.5 font-medium dark:text-white">Remember me?</label>
                                </div>
                                <button type="submit" onClick={this.submit.bind(this)} className="block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-lel-100 shadow-sm sm:text-sm mb-4 hover:bg-gray-600 dark:hover:bg-lel-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                                    Sign In
                                </button>
                                <FormErrors className="mb-4" errors={this.state.errors}/>
                            </>
                        ) : (
                            <>
                                {this.state.id.custom.title !== undefined && (
                                    <>
                                        <h1 className="text-center mb-2 text-gray-900 dark:text-white text-sm font-semibold">{this.state.id.custom.title}</h1>
                                        <p className="text-center dark:text-gray-400 text-sm mb-10">{this.state.id.custom.desc}</p>
                                    </>
                                )}
                                {this.state.name === "forgot-password" && (
                                    <>
                                        <div className="relative">
                                            <label htmlFor="email-address" className="sr-only">Email address</label>
                                            <input id="email-address" name="email" type="email" required="" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Email Address"/>
                                        </div>
                                        <button type="submit" className="block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-lel-100 shadow-sm sm:text-sm mb-10 hover:bg-gray-600 dark:hover:bg-lel-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                                            Reset your password
                                        </button>
                                    </>
                                )}
                                {this.state.name === "register" && (
                                    <>
                                        <div className="relative">
                                            <label htmlFor="username" className="sr-only">Username</label>
                                            <input id="username" name="username" maxLength="50" type="text" required="" value={this.state.username} onChange={(e) => this.setState({username: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Username"/>
                                        </div>
                                        <div className="relative">
                                            <label htmlFor="email-address" className="sr-only">Email Address</label>
                                            <input id="email-address" name="email" maxLength="100" type="email" required="" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Email Address"/>
                                        </div>
                                        <div className="relative">
                                            <label htmlFor="password" className="sr-only">Password</label>
                                            <input id="password" name="password" maxLength="50" type="password" required="" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Password"/>
                                        </div>
                                        <div className="relative">
                                            <label htmlFor="password" className="sr-only">Matching Password</label>
                                            <input id="matchingPassword" name="matchingPassword" maxLength="50" type="password" required="" value={this.state.matchingPassword} onChange={(e) => this.setState({matchingPassword: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Matching Password"/>
                                        </div>
                                        <div className="relative">
                                            <label htmlFor="code" className="sr-only">Code</label>
                                            <input id="code" name="code" type="text" maxLength="50" required="" value={this.state.code} onChange={(e) => this.setState({code: e.target.value})} className="text-gray-900 dark:text-white ring-gray-900 ring-opacity-5 placeholder-gray-400 dark:placeholder-gray-100 appearance-none bg-white dark:bg-dark-800 rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-6 focus:border-lel-100 focus:ring-lel-100 focus:outline-none" placeholder="Code"/>
                                        </div>
                                        <button type="submit" onClick={this.submit.bind(this)} className="block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-lel-100 shadow-sm sm:text-sm mb-4 hover:bg-gray-600 dark:hover:bg-lel-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                                            Register
                                        </button>
                                        <FormErrors errors={this.state.errors}/>
                                    </>
                                )}
                                {this.state.name === "get-access" && (
                                    <NavLink to="/">
                                        <button type="submit" className="block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-lel-100 shadow-sm sm:text-sm mb-10 hover:bg-gray-600 dark:hover:bg-lel-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
                                            Go Home
                                        </button>
                                    </NavLink>
                                )}
                            </>
                        )}

                        {this.state.id.forgotPass !== undefined && (
                            <p className="text-center">
                                <NavLink to="/sign-in/forgot-password" className="text-sm underline hover:text-gray-900 dark:text-white dark:hover:text-gray-300">Forgot password?</NavLink>
                            </p>
                        )}
                    </div>
                </main>
                {this.state.id.footer !== false && (
                    <footer className="relative z-10 flex-none text-sm text-center py-4 px-4 sm:px-6 lg:px-8">
                        <div className="text-gray-900 dark:text-white sm:flex sm:items-center sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                            <p>{this.state.id.footer.title}</p>
                            {this.state.id.footer.url.startsWith("/") ? (
                                <NavLink to={this.state.id.footer.url} className="rounded-md border border-gray-300 hover:border-gray-400 py-2 px-10 font-medium flex items-center justify-center">
                                    {this.state.id.footer.name}
                                    <svg aria-hidden="true" width="11" height="10" fill="none" className="flex-none ml-1.5">
                                        <path d="M5.977 9.639L10.616 5 5.977.362l-.895.89L8.19 4.353H.384v1.292H8.19L5.082 8.754l.895.885z" fill="currentColor"/>
                                    </svg>
                                </NavLink>
                            ) : (
                                <a href={this.state.id.footer.url} className="rounded-md border border-gray-300 hover:border-gray-400 py-2 px-10 font-medium flex items-center justify-center">
                                    {this.state.id.footer.name}
                                    <svg aria-hidden="true" width="11" height="10" fill="none" className="flex-none ml-1.5">
                                        <path d="M5.977 9.639L10.616 5 5.977.362l-.895.89L8.19 4.353H.384v1.292H8.19L5.082 8.754l.895.885z" fill="currentColor"/>
                                    </svg>
                                </a>
                            )}
                        </div>
                    </footer>
                )}
            </>
        );
    }

    submit() {
        fetchBodyJson(`/api/auth/${this.state.name}`, JSON.stringify(this.state), (data) => {
            const status = data.status;

            if (status === "SUCCESS")
                window.location.pathname = "/";
            if (status === "FAILURE")
                this.setState({errors: data.errors});
        }, () => {}, true)
    }
}