import React from 'react';

export default class FormError extends React.Component {

    render() {
        return (
            <div>
                - {this.props.text}
            </div>
        )
    }
}