import { Component } from 'react';

import PropTypes from 'prop-types';

export default class CardTitle extends Component {

    render() {
        return (
            <div className="rounded-md bg-gray-100 dark:bg-background/40 shadow-md">
                <div className="p-2 border-b border-b border-gray-300 dark:border-background/70">
                    <div className="text-lel-100 font-biotif font-bold text-lg pt-1 pl-2">{this.props.title}</div>
                </div>
                {this.props.children}
            </div>
        )
    }
}

CardTitle.propTypes = {
    title: PropTypes.string.isRequired
}