import React from 'react';

import Moment from 'react-moment';
import moment from 'moment';

export default class LLMoment extends React.Component {

    render() {
        const unix = this.props.unix;
        const fromNow = false;// new Date().getTime() - unix < 3600000;

        return (
            <span title={moment.unix(unix / 1000).format("MMMM DD, YYYY [at] LT")}>
                <Moment interval={30000} className="span" fromNow={fromNow} calendar={{sameDay: '[Today at] LT', nextDay: '[Tomorrow at] LT', nextWeek: 'MMM DD, YYYY [at] hh:mm A', lastDay: '[Yesterday at] LT', lastWeek: 'MMM DD, YYYY [at] hh:mm A', sameElse: 'MMM DD, YYYY [at] hh:mm A'}} unix>
                    {unix / 1000}
                </Moment>
            </span>
        )
    }
}
