import { Component } from 'react';

import { fetchBodyJson, fetchJson } from '../../../utils/utils';
import { XCircleIcon } from '@heroicons/react/outline';

import Notification from '../../../components/ui/Notification';
import Input from '../../../components/form/Input';
import Button from '../../../components/ui/Button';

export default class DashboardWebsite extends Component {


    constructor(props) {
        super(props);

        this.state = {
            cNames: undefined,
            notification: undefined,
            message: undefined
        }

        this.updateCNames = this.updateCNames.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        fetchJson('/api/auth/setting/get', s => {
            if(this.mounted)
                this.setState({cNames: s.cNames === "" ? [] : s.cNames.split(",")});
        }, (f) => {
            console.error(f)
            if(this.mounted)
                this.setState({notification: <Notification title="Error" type="error" message="Failed to retrieve Custom URLs."/>});
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    notification() {
        return this.state.notification || null;
    }

    setting(setting) {
        return this.state.setting === undefined ? "" : this.state.setting[setting];
    }

    updateCNames(list) {
        fetchBodyJson('/api/auth/setting/update', JSON.stringify({name: "cnames", value: list.join(",")}), s => {
            this.setState({notification: <Notification message="Updated Custom URLs!" key={list + "cname"}/>});
        }, f => {
            this.setState({notification: <Notification title="Failed to Save" type="error" message="Failed to update Custom URLs." key={list + "cname"}/>});
        });
    }

    render() {
        return (
            <div>
                {this.notification()}
                <div className="flex items-center space-x-3 pb-2.5">
                    <h5 className="text-[13px] font-medium font-biotif pt-0.5 tracking-wide">Custom URLs</h5>
                    <Button name="Add URL" size="xs" onClick={() => {
                        if(this.state.cNames.length >= 5) {
                            this.setState({message: "You can only have 5 Custom URLs."});
                        } else {
                            this.setState({cNames: [...this.state.cNames, ""]});
                        }
                    }}/>
                </div>
                {this.state.cNames !== undefined && (
                    <div className="w-1/6 space-y-3">
                        {this.state.cNames.map((cName, i) => (
                            <div className="flex items-center space-x-4">
                                <Input key={i} value={this.state.cNames[i]} onChange={(value) => {
                                    let list = this.state.cNames;
                                    list[i] = value;

                                    this.updateCNames(list);
                                }}/>
                                <XCircleIcon className="relative left-[-40px] w-4 h-4 text-lel-100 cursor-pointer" onClick={() => {
                                    let list = this.state.cNames;
                                    list.splice(i, 1);

                                    this.setState({cNames: list});
                                    this.updateCNames(list);
                                }}/>
                            </div>
                        ))}
                        {this.state.cNames.length === 0 && <div className="font-biotif pt-2 dark:text-gray-300 text-sm">No Custom URLs, maybe add one?</div>}
                    </div>
                )}
                {this.state.message !== undefined && <div className="font-biotif text-red-400 pt-4 text-sm">- {this.state.message}</div>}
            </div>
        )
    }
}