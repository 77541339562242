import { Component } from 'react';

import { NavLink } from 'react-router-dom';

import Button from '../../../components/ui/Button';

export default class Dashboard404 extends Component {

    render() {
        return (
            <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                    <main className="items-center sm:flex">
                        <p className="text-4xl font-extrabold text-lel-100 sm:text-5xl">404</p>
                        <div className="sm:ml-6 py-5 sm:border-l sm:border-gray-200 dark:sm:border-dark-400 sm:pl-6">
                            <div>
                                <h1 className="text-4xl font-biotif font-bold text-gray-900 dark:text-gray-50 tracking-tight sm:text-5xl">Page Not Found</h1>
                                <p className="mt-1.5 text-base text-gray-500 dark:text-gray-200 font-medium font-biotif">Unknown page.</p>
                            </div>
                            <div className="mt-4 flex space-x-3 sm:border-l sm:border-transparent">
                                <NavLink to="/dashboard">
                                    <Button name="Go to Overview" size="sm"/>
                                </NavLink>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        )
    }
}