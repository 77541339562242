import { Component } from 'react';

import PropTypes from 'prop-types';
import CardList from '../../../components/ui/CardList';

export default class DashboardStats extends Component {

    render() {
        return (
            <div>
                <CardList>
                    {this.props.stats.map((item) => (
                        <div key={item.name} className="flex items-center space-x-5 px-4 py-5 bg-gray-200 dark:bg-background/30 shadow-md rounded-lg overflow-hidden sm:p-5">
                            {item.icon !== undefined && <item.icon className="h-14 w-14 p-3 text-gray-800 dark:text-white rounded-full dark:bg-dark-400/30" aria-hidden="true"/>}
                            <div>
                                <dt className="font-biotif text-[15px] font-medium text-gray-500 dark:text-gray-300 truncate">{item.name}</dt>
                                <dd className="text-2xl font-semibold text-gray-900 dark:text-gray-200">{item.stat}</dd>
                            </div>
                        </div>
                    ))}
                </CardList>
            </div>
        )
    }
}

DashboardStats.propTypes = {
    stats: PropTypes.array.isRequired
}