import { Component } from 'react';

import FormError from './FormError';

export default class FormErrors extends Component {

    render() {
        return (
            <>
                {this.props.errors.length > 0 && (
                    <div className={this.props.className !== undefined ? `${this.props.className} text-red-400 text-sm` : "text-red-400 text-sm"}>
                        {this.props.errors.map(error => <FormError key={error.message} text={error.message}/>)}
                    </div>
                )}
            </>
        );
    }
}