import { Component } from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';
import { fetchJson } from './utils/utils';

import Header from './components/nav/Header';
import Footer from './components/nav/Footer';

import Home from './pages/Home';
import Pricing from './pages/Pricing';

import Dashboard from './pages/dashboard/Dashboard';
import DashboardSubscription from './pages/dashboard/pages/DashboardSubscription';
import DashboardOverview from './pages/dashboard/pages/DashboardOverview';
import Notification from './components/ui/Notification';
import Id from './pages/Id';
import SignIn from './pages/SignIn';
import Logout from './pages/Logout';

export function retrieveLoggedInAccount(callback) {

    fetchJson('/api/auth/status', data => {
        if (data.status === "loggedIn") {
            callback({loggedIn: true, ...data.data});
        } else {
            callback({loggedIn: false});
        }
    }, e => callback({loggedIn: false}));
}

class LelLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dev: process.env.NODE_ENV === 'development',
            loaded: false,
            account: undefined,
            theme: localStorage.theme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
        }

        this.onThemeChange = this.onThemeChange.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        this.syncData();
        this.unlisten = this.props.history.listen(() => this.syncData());

        document.addEventListener('themeChange', this.onThemeChange);
    }

    componentWillUnmount() {
        this.unlisten();

        this.mounted = false;

        document.removeEventListener('themeChange', this.onThemeChange);
    }

    onThemeChange() {
        this.setState({theme: localStorage.theme || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')});
    }

    syncData() {
        retrieveLoggedInAccount(data => {
            if(this.mounted)
                this.setState({loaded: true, account: data})
        });
    }

    render() {
        const moreProps = {account: {dev: this.state.dev, loaded: this.state.loaded, ...this.state.account}};
        const dashProps = {theme: this.state.theme, ...moreProps, ...this.props};

        return (
            <div className="flex flex-col antialiased transition-colors duration-500 min-h-screen bg-light dark:text-white dark:bg-darker">
                <div className="flex-grow">
                    <Header {...moreProps} {...dashProps}/>

                    <main className={!this.props.history.location.pathname.startsWith("/dashboard") ? "pt-[110px] xs2:pt-[70px] max-w-7xl mx-auto" : ""}>
                        <Switch>
                            <Route exact path='/' render={(props) => <Home {...props} {...moreProps} {...this.props}/>}/>
                            <Route exact path='/pricing' render={(props) => <Pricing {...props} {...moreProps} {...this.props}/>}/>

                            <Route exact path='/dashboard' render={(props) => <Dashboard {...dashProps} {...props}/>}/>
                            <Route exact path='/dashboard/:page' render={(props) => <Dashboard {...dashProps} {...props}/>}/>

                            <Route exact path="/sign-in" render={(props => <SignIn {...this.state} {...this.props} {...props}/>)}/>
                            <Route exact path="/sign-in/:id" render={(props => <SignIn {...this.state} {...this.props} {...props}/>)}/>

                            <Route exact path="/logout" render={(props => <Logout {...this.state} {...this.props} {...props}/>)}/>

                            <Route exact path='/discord' render={() => {
                                window.location.replace('https://discord.gg/PNHPSJURed');
                                return null;
                            }}/>

                            <Route exact path="/:id" render={(props => <Id {...this.state} {...this.props} {...props}/>)}/>
                        </Switch>
                    </main>

                    {/*<BackgroundGradient theme="light" location="left"/>*/}
                    {/*<BackgroundGradient theme="light" location="right"/>*/}
                </div>

                <Footer/>
            </div>
        );
    }
}

export default withRouter(LelLink);