import { Component } from 'react';

import DashboardStats from '../components/DashboardStats';

import { ArchiveIcon, CursorClickIcon, LinkIcon, UserGroupIcon } from '@heroicons/react/outline';
import { fetchJson, formatBytes } from '../../../utils/utils';
import { Loading } from '../../../components/ui/Loading';

export default class DashboardOverview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            linkAccount: undefined
        }
    }

    componentDidMount() {
        this.mounted = true;

        fetchJson('/api/link/account', s => {
            if(this.mounted)
                this.setState({linkAccount: s});
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if(this.state.linkAccount === undefined)
            return <Loading/>;

        return (
            <div>
                <DashboardStats stats={[
                    { name: 'Total Links', stat: this.state.linkAccount.links.length, icon: LinkIcon },
                    { name: 'Total Clicks', stat: this.state.linkAccount.links.map(s => s.clicks).reduce((partialSum, a) => partialSum + a, 0), icon: CursorClickIcon },
                    { name: 'Total User Clicks', stat: this.state.linkAccount.diffUsers, icon: UserGroupIcon },
                    { name: 'Used Storage', stat: formatBytes(this.state.linkAccount.size), icon: ArchiveIcon }
                ]}/>
            </div>
        )
    }
}