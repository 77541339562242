import React, { Component } from 'react';

import { capitalize, fetchBodyJson, fetchText, formatBytes } from '../utils/utils';
import { Helmet } from 'react-helmet';

import Moment from '../utils/LLMoment';
import Highlight from '../utils/Highlight';
import CardTitle from '../components/ui/CardTitle';
import Button from '../components/ui/Button';

export default class Id extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            link: undefined,
            // link: { data: {
            //     "id": "lellink+ShareX",
            //     "type": "text/plain",
            //     "extension": ".txt",
            //     "author": "EazyFTW",
            //     "clicks": 14,
            //     "created": 1638303307501
            // }},

            text: undefined,
            size: undefined,
            error: false,
            modal: false
        }

        this.modalRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        fetchBodyJson("/api/link", this.state.id, (data) => {
            this.setState({link: data.data, error: data.status === "FAILURE"})
            if(data.status === "SUCCESS") {
                if(data.data.type.includes("text") || data.data.type === "application/json") {
                    fetchText(`https://lel.link/${data.data.id}${data.data.extension}`, (s) => this.setState({text: s}), (f) => console.error(f), true)
                }
            }
        }, () => this.setState({error: true}), true);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.modalRef && this.modalRef.current != null && !this.modalRef.current.contains(event.target))
            this.setState({modal: false});
    }

    render() {
        // if(this.state.link !== undefined && this.state.link !== undefined && this.state.link.author === "TehZombiJesus" && window.location.hostname.includes("lel.link"))
        //     window.location.href = `https://anus.hair${window.location.pathname}`;
        //     // return <Redirect to={`https://anus.hair${window.location.pathname}`}/>;

        return (
            <>
                <div className="mx-auto mx-2 xl:mx-0 pt-4 md:pt-8 lg:pt-10">
                    {this.state.error ? (
                        <div className="text-center">
                            An error has occurred.
                        </div>
                    ) : (
                        <>
                            {this.state.link !== undefined ? (
                                <div>
                                    <Helmet>
                                        <title>{this.state.link.id} - lel.link</title>
                                    </Helmet>
                                    <div className="md:grid md:grid-cols-7 md:gap-3 text-gray-600 dark:text-white">
                                        <div className="col-span-5">
                                            <CardTitle title={capitalize(this.state.link.type.split("/")[0] === "application" ? "File" : this.state.link.type.split("/")[0])}>
                                                {this.state.link.type.includes("video") ? (
                                                    <video className="mx-auto p-2" controls>
                                                        <source type={this.state.link.type} src={`https://lel.link/${this.state.link.id}${this.state.link.extension}`}/>
                                                    </video>
                                                ) : (
                                                    <>
                                                        {this.state.link.type.includes("text") || this.state.link.type === "application/json" ? (
                                                            <>
                                                                {this.state.text !== undefined ? (
                                                                    <Highlight>
                                                                        {this.state.text}
                                                                    </Highlight>
                                                                ) : (
                                                                    <div className="text-center py-2">
                                                                        Loading...
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <img onClick={() => this.setState({modal: true})} className="mx-auto p-2 py-6 cursor-pointer" src={`https://lel.link/${this.state.link.id}${this.state.link.extension}`} alt={this.state.link.id}/>
                                                        )}
                                                    </>
                                                )}
                                            </CardTitle>
                                        </div>
                                        <div className="col-span-2 space-y-3">
                                            <CardTitle title={`${this.state.link.id}${this.state.link.extension}`}>
                                                <div className="space-y-1 py-2 mx-4 font-biotif">
                                                    <CardId name="Author" desc={this.state.link.author}/>
                                                    <CardId name="Type" desc={this.state.link.type}/>
                                                    <CardId name="Size" desc={formatBytes(this.state.link.size)}/>
                                                    <CardId name="Created" desc={<Moment unix={this.state.link.created}/>}/>
                                                </div>
                                            </CardTitle>
                                            <div className="flex justify-center items-center space-x-3">
                                                <a href={`https://lel.link/${this.state.link.id}${this.state.link.extension}`} target="_blank" rel="noreferrer">
                                                    <Button name="Open Original" size="sm"/>
                                                </a>
                                                {/*<a href={`https://lel.link/${this.state.link.id}${this.state.link.extension}`} download>*/}
                                                {/*    <Button name="Download" size="sm"/>*/}
                                                {/*</a>*/}
                                            </div>
                                            {/*<CardID name={`${this.state.link.id}${this.state.link.extension}`} links={[*/}
                                            {/*    {*/}
                                            {/*        name: "Open Original",*/}
                                            {/*        url: `https://lel.link/${this.state.link.id}${this.state.link.extension}`*/}
                                            {/*    },*/}
                                            {/*    {*/}
                                            {/*        name: "Download",*/}
                                            {/*        url: `https://lel.link/${this.state.link.id}${this.state.link.extension}`,*/}
                                            {/*        download: true*/}
                                            {/*    }*/}
                                            {/*]}/>*/}
                                            {/*<CardID name="Author" desc={this.state.link.author}/>*/}
                                            {/*<CardID name="Type" desc={`${this.state.link.extension} (${this.state.link.type})`}/>*/}
                                            {/*<CardID name="Size" desc={formatBytes(this.state.link.size)}/>*/}
                                            {/*<CardID name="Created" desc={<Moment unix={this.state.link.created}/>}/>*/}
                                            {/*<div className="rounded-md bg-gray-100 dark:bg-gray-1d shadow-md">*/}
                                            {/*    <div className="flex items-center justify-between border-b border-gray-300 dark:border-gray-2d p-2">*/}
                                            {/*        <div className="text-lel-100 font-bold text-lg pt-0.5 pl-2">{this.state.link.id}{this.state.link.extension}</div>*/}
                                            {/*        <a href={`https://lel.link/${this.state.link.id}${this.state.link.extension}`} className="font-bold text-gray-600 dark:text-white pr-1">Open Original</a>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="p-2 space-y-px">*/}
                                            {/*        <div><span className="text-lel-100 font-bold">- Name: </span><span className="font-medium">{this.state.link.id}</span></div>*/}
                                            {/*        <div><span className="text-lel-100 font-bold">- Type: </span><span className="font-medium">{this.state.link.type} ({this.state.link.extension.replace(".", "")})</span></div>*/}
                                            {/*        <div><span className="text-lel-100 font-bold">- Size: </span><span className="font-medium">{this.state.size === undefined ? "Calculating..." : formatBytes(this.state.size)}</span></div>*/}
                                            {/*        <div><span className="text-lel-100 font-bold">- Created: </span><span className="font-medium"><Moment unix={this.state.link.created}/></span></div>*/}
                                            {/*        <div><span className="text-lel-100 font-bold">- Author: </span><span className="font-medium">{this.state.link..author}</span></div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center">
                                    Loading..
                                </div>
                            )}
                        </>
                    )}
                </div>
                {this.state.modal && (
                    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 dark::bg-background/40 dark:bg-opacity-75 bg-opacity-75 transition-opacity" aria-hidden="true"/>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white dark:bg-background rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-2xl">
                                <div ref={this.modalRef} className="bg-gray-1d pt-3 pb-1">
                                    <div className="flex items-center justify-between px-3">
                                        <div className="font-bold text-lel-100">Modal</div>
                                        <div className="cursor-pointer" onClick={() => this.setState({modal: false})}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <img className="mx-auto p-2" src={`https://lel.link/${this.state.link.id}${this.state.link.extension}`} alt={this.state.link.id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

function CardId({name, desc}) {
    return (
        <div className="flex items-center justify-between">
            <div className="font-medium text-lel-100">
                {name}:
            </div>
            <div>
                {desc}
            </div>
        </div>
    )
}