import { Component } from 'react';

export default class CardList extends Component {

    render() {
        return (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {this.props.children}
            </div>
        )
    }
}