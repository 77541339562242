import { Component } from 'react';

import { fetchJson } from '../utils/utils';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

export default class Logout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            error: false
        }
    }

    componentDidMount() {
        fetchJson("/api/auth/logout", () => this.setState({redirect: true}), () => this.setState({error: true}), true);
    }

    render() {
        if(this.state.redirect)
            return <Redirect to="/"/>;

        return (
            <div className="px-4 md:px-6 lg:px-8">
                <Helmet>
                    <title>Logout - lel.link</title>
                </Helmet>
                <div className="pt-8 md:pt-10 lg:pt-12 text-gray-600 dark:text-white text-4xl font-extrabold">
                    <div className="text-5xl bg-clip-text text-transparent bg-gradient-to-r via-yellow-100 from-lel-100 to-orange-500 pb-1">
                        Logging you out..
                    </div>
                    Please wait..
                </div>

                {this.state.error && (
                    <div className="text-md mt-4">
                        Uh oh! Something went wrong!
                    </div>
                )}
            </div>
        )
    }
}