import { Component, Fragment } from 'react';

import { NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'

import { motion, AnimateSharedLayout } from 'framer-motion';

import ThemeToggle from '../../hooks/ThemeToggle';
import clsx from 'clsx';

const pages = [
    "Home",
    "Pricing",
    // "Downloads",
    "Dashboard",
    // "Blog"
];

export default class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    componentDidMount() {
        this.listener = this.props.history.listen(() => this.setState({open: false}));
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        if(this.props.history.location.pathname.startsWith("/dashboard"))
            return null;//<ThemeToggle/>;

        return (
            <AnimateSharedLayout>
                <header className="flex flex-row flex-wrap items-center text-black dark:text-white font-[12px] min-h-[59px] w-full absolute top-0 z-[3] mt-[13px] xs2:mt-0">
                    <NavLink to="/" className="ml-5 mt-1 overflow-x-auto">
                        <img src="https://cdn.lel.link/images/logo_small.png" alt="LelLink" className="h-9 w-auto rounded-md hover:bg-background p-1"/>
                    </NavLink>

                    <nav className="text-center flex-1 order-2 basis-[initial] xs2:order-0 overflow-x-scroll xs3:overflow-y-hidden px-3 xs1:mx-0 xs1:pr-12 xs2:pr-16">
                        <ul className="mt-0 pt-0 list-none inline-flex relative top-[5px] xs1:justify-around">
                            {pages.map(page => {
                                if(page === "Dashboard" && (!window.location.host.includes("lel.link")) && (!window.location.host.includes("localhost:")))
                                    return null;

                                const path = (page === "Home" ? "/" : `/${page.toLowerCase()}`);

                                return (
                                    <li key={page}>
                                        <NavLink to={path} className="border-0 relative hover:opacity-100 focus:opacity-100">
                                            <motion.span className={clsx("font-biotif text-secondary cursor-pointer inline-block text-[12px] font-medium tracking-[1.2px] p-[20px] transition-color uppercase hover:!text-black/70 dark:hover:!text-primary bg-after", this.props.history.location.pathname === path && "!text-black/70 dark:!text-primary after:opacity-100")} onHoverStart={() => this.setState({hovered: page})} onHoverEnd={() => this.setState({hovered: ""})}>
                                                {this.state.hovered === page && (
                                                    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} layoutId="nav" className="absolute top-[-9px] left-0 right-0 bg-gray-200 dark:bg-background p-[20px] rounded-[8px] z-[-1]"/>
                                                )}
                                                {page}
                                            </motion.span>
                                        </NavLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>

                    <Menu as="div" className="flex xs1:block items-center mt-1 pr-5 ml-auto xs1:absolute xs1:right-0 cursor-pointer">
                        <div>
                            <Menu.Button>
                                <img src={this.props.account === undefined || this.props.account.icon === undefined ? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" : this.props.account.icon} alt="" className="h-9 w-auto rounded-md hover:bg-background p-1"/>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 w-44 mr-3 origin-top-right divide-y divide-gray-100 dark:divide-dark-500 rounded-md bg-gray-50 dark:bg-background/90 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {this.props.account !== undefined && this.props.account.loggedIn ? (
                                        <>
                                            <div className="!px-1 !py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <NavLink to="logout"
                                                                 className={`${
                                                                     active ? 'bg-lel-100' : ''
                                                                 } group flex w-full items-center !text-white dark:!text-white font-semibold rounded-md px-2 py-2 text-sm`}
                                                        >
                                                            Logout
                                                        </NavLink>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="!px-1 !py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <a href="/sign-in"
                                                       className={`${
                                                           active ? 'bg-lel-100' : ''
                                                       } group flex w-full items-center !text-white dark:!text-white font-semibold rounded-md px-2 py-2 text-sm`}
                                                    >
                                                        Sign In
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    )}
                                </Menu.Items>
                            </Transition>
                        </div>
                    </Menu>
                </header>

                <ThemeToggle/>
            </AnimateSharedLayout>
        )
    }
}