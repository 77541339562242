import { Component } from 'react';

import { capitalize, fetchJson } from '../../../utils/utils';
import { loadStripe } from '@stripe/stripe-js';
import { Loading } from '../../../components/ui/Loading';

import { NavLink, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';

import DashboardSubscriptionSubscribe from './DashboardSubscriptionSubscribe';
import Button from '../../../components/ui/Button';

import CardList from '../../../components/ui/CardList';
import Card from '../../../components/ui/Card';
import LLMoment from '../../../utils/LLMoment';

export default class DashboardSubscription extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subscriptions: undefined,
            subscriptionData: undefined,
            stripePromise: undefined,
            search: this.props.location.search,
            prices: []
        }

        this.updateSubscriptions = this.updateSubscriptions.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        fetchJson('/api/stripe/config', s => {
            if(this.mounted) {
                const stripePromise = loadStripe(s.publishable_key);

                this.setState({stripePromise: stripePromise, prices: s.prices.reverse()});
            }
        });

        this.updateSubscriptions();
    }

    updateSubscriptions() {
        fetchJson('/api/stripe/subscriptions', s => {
            if(this.mounted) {
                this.setState({subscriptions: s.subscriptions});

                if(s.clientSecrets !== undefined && Object.keys(s.clientSecrets).length > 0) {
                    const subscriptionId = Object.keys(s.clientSecrets)[0];
                    const clientSecret = s.clientSecrets[subscriptionId];

                    this.setState({subscriptionData: {subscriptionId: subscriptionId, clientSecret: clientSecret, continue: true}});
                }
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createSubscription = async (priceId) => {
        fetchJson(`/api/stripe/createSubscription/${priceId}`, s => {
            if(this.mounted && s.code !== "500")
                this.setState({subscriptionData: s});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({search: this.props.location.search})
        }
    }

    cancelSubscription(subscriptionId) {
        // Cancel the subscription.
        fetchJson(`/api/stripe/cancelSubscription/${subscriptionId}`, s => {
            this.updateSubscriptions();
        }, f => {
            console.error(f);
        })
    }

    subscriptions(subscriptions) {
        return (
            <CardList>
                {subscriptions.map(s => {
                    return (
                        <Card key={s.id}>
                            <div className="space-y-2.5">
                                <h1 className="font-bold font-biotif -mb-3">{capitalize(s.plan)}</h1>
                                <p className="font-light font-biotif text-sm">{s.id}</p>
                                {s.status !== "active" && s.cancelledAt != null && (
                                    <div>
                                        <h5 className="text-sm font-biotif font-semibold">Cancel Date</h5>
                                        <LLMoment unix={s.cancelledAt * 1000} />
                                    </div>
                                )}
                                {s.status === "active" && (
                                    <div>
                                        <h5 className="text-sm font-biotif font-semibold">Renewal</h5>
                                        <LLMoment unix={s.currentPeriodEnd * 1000} />
                                    </div>
                                )}

                                {s.lastFour !== "Unknown" && (
                                    <div>
                                        <h5 className="text-sm font-biotif font-semibold">Card</h5>
                                        <span className="font-extrabold">•••• •••• ••••</span> {s.lastFour} ({capitalize(s.brand)})
                                    </div>
                                )}
                                {s.status !== "incomplete_expired" && s.status !== "canceled" && (
                                    <div className="space-x-2.5 pt-1">
                                        <Button name="Cancel" size="sm" onClick={() => this.cancelSubscription(s.id)} />
                                        {/*<Button name="Change Plan" size="sm" onClick={() => this.updateSubscription(s.id)} />*/}
                                    </div>
                                )}
                            </div>
                        </Card>
                    )
                })}
            </CardList>
        )
    }

    render() {
        if(this.state.stripePromise === undefined || this.state.subscriptions === undefined)
            return <Loading/>;

        const params = new URLSearchParams(this.state.search);

        const pages = ['cancel', 'payment'];

        const page = pages.find(p => params.has(p));

        if(page === undefined) {
            if(this.state.subscriptionData !== undefined)
                return <Redirect to='/dashboard/subscription?payment'/>;

            if(this.state.subscriptions !== undefined && this.state.subscriptions.filter(s => s.status === "active").length > 0) {
                return (
                    <div>
                        <div className="font-biotif font-medium pb-2">Active Subscription</div>
                        {this.subscriptions(this.state.subscriptions.filter(s => s.status === "active"))}
                        <div className="font-biotif font-medium pb-2 pt-6">Expired Subscriptions</div>
                        {this.subscriptions(this.state.subscriptions.filter(s => s.status !== "active"))}
                    </div>
                )
            } else {
                return (
                    <>
                        <div className="font-biotif font-medium pb-2">Wanna {this.state.subscriptions !== undefined && this.state.subscriptions.length > 0 && "re"}subscribe? Pick one!</div>
                        <CardList>
                            {this.state.prices.map((price) => {
                                return (
                                    <Card key={price.id}>
                                        <h3 className="font-biotif font-bold">{capitalize(price.name)}</h3>

                                        <p className="font-biotif">
                                            ${price.unitAmount / 100} / month
                                        </p>

                                        <div className="mt-2.5">
                                            <Button name="Select" size="sm" onClick={() => this.createSubscription(price.id)}/>
                                        </div>
                                    </Card>
                                )
                            })}
                        </CardList>
                        {this.state.subscriptions !== undefined && this.state.subscriptions.filter(s => s.status !== "active").length > 0 && (
                            <div className="mt-6">
                                <div className="font-biotif font-medium pb-2">Expired Subscriptions</div>
                                {this.subscriptions(this.state.subscriptions.filter(s => s.status !== "active"))}
                            </div>
                        )}
                        <div className="mt-7">
                            <NavLink to="/pricing">
                                <Button name="Learn More" size="md"/>
                            </NavLink>
                        </div>
                    </>
                );
            }
        } else if (page === "payment" && this.state.subscriptionData !== undefined) {
            return (
                <Elements stripe={this.state.stripePromise}>
                    <DashboardSubscriptionSubscribe location={this.state.subscriptionData} theme={this.props.theme}/>
                </Elements>
            )
        }
    }
}