import { Fragment, useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';

import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

export default function Notification({title = "Successfully Saved", type = "success", message, shouldTimeout  = true, timeoutTime = 8000}) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);

            if(shouldTimeout)
                setTimeout(() => {
                    setShow(false);
                }, timeoutTime);
        }, 500);
    }, []);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none lg:p-6 lg:items-start"
            >
                <div className="w-full flex flex-col items-center space-y-4 lg:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-1000 transition"
                        enterFrom="translate-y-2 opacity-0 lg:translate-y-0 lg:translate-x-80"
                        enterTo="translate-y-0 opacity-100 lg:translate-x-0"
                        leave="transition ease-in duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-gray-100 dark:bg-dark-600 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-center items-start">
                                    <div className="flex-shrink-0">
                                        {type === "success" && <CheckCircleIcon className="h-8 w-8 text-green-400" aria-hidden="true" />}
                                        {type === "error" && <XCircleIcon className="h-8 w-8 text-red-500" aria-hidden="true" />}
                                        {type === "warning" && <ExclamationCircleIcon className="h-8 w-8 text-orange-500" aria-hidden="true" />}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-1">
                                        <p className="text-sm font-semibold text-gray-900 dark:text-gray-100 font-biotif">{title}</p>
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-300 font-biotif">{message}</p>
                                    </div>
                                    <div className="ml-4 mr-1.5 flex-shrink-0 flex">
                                        <button
                                            className="rounded-md inline-flex text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                setShow(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}