import { Component } from 'react';

import { MenuIcon, XIcon, } from '@heroicons/react/outline';
import { NavLink, Redirect } from 'react-router-dom';

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { AnimateSharedLayout, motion } from 'framer-motion';

import clsx from 'clsx';
import ThemeToggle from '../../hooks/ThemeToggle';

import logoNoText from '../../assets/imgs/logo_small.png';
import DashboardOverview from './pages/DashboardOverview';
import { capitalize } from '../../utils/utils';
import Dashboard404 from './pages/Dashboard404';
import DashboardSubscription from './pages/DashboardSubscription';
import DashboardSettings from './pages/DashboardSettings';
import DashboardLinks from './pages/DashboardLinks';
import DashboardWebsite from './pages/DashboardWebsite';

const navigation = [
    { name: 'Overview', to: '/dashboard'},
    { name: 'Links', to: '/dashboard/links'},
    { name: 'Website', to: '/dashboard/website'},
    { name: 'Subscription', to: '/dashboard/subscription'},
    { name: 'Settings', to: '/dashboard/settings'},
]

export default class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: false,
            account: undefined,
            page: props.match.params.page,
            hovered: ""
        }

        this.Header = this.Header.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        this.unlisten = this.props.history.listen(() => {
            if(this.state.sidebarOpen && this.mounted)
                this.setState({sidebarOpen: false});
        });
    }

    componentWillUnmount() {
        this.mounted = false;

        this.unlisten();
    }

    Header() {
        return (
            <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 dark:border-dark-700 bg-gray-100 dark:bg-darker">
                <div className="flex-1 flex flex-col pt-6 pb-4 overflow-y-auto">
                    <div className="flex items-center mx-auto ml-12 flex-shrink-0 px-4">
                        <NavLink to="/">
                            <img
                                className="h-9 w-auto"
                                src="https://cdn.lel.link/images/small_text.png"
                                alt=""
                            />
                        </NavLink>
                    </div>
                    <AnimateSharedLayout>
                        <nav className="mt-12 flex-1 absolute z-[3]" aria-label="Sidebar">
                            <ul className="pl-8 mt-0 pt-0 list-none relative">
                                {navigation.map((item) => (
                                    <li key={item.name} className="h-12">
                                        <NavLink to={item.to} className="border-0 relative hover:opacity-100 focus:opacity-100">
                                            <motion.span
                                                onHoverStart={() => this.setState({hovered: item.name})} onHoverEnd={() => this.setState({hovered: ""})}
                                                className={clsx(
                                                    this.props.history.location.pathname === item.to && '!text-black/70 dark:!text-primary after:opacity-100',
                                                    'font-biotif text-secondary cursor-pointer inline-block text-[12px] font-medium tracking-[1.2px] p-[20px] transition-color uppercase hover:!text-black/70 dark:hover:!text-primary db-after'
                                                )}
                                            >
                                                {this.state.hovered === item.name && (
                                                    <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} layoutId="nav" className={clsx(this.props.history.location.pathname === item.to ? "left-[-15px]" : "left-0", "absolute mt-[-12px] right-0 bg-gray-200 dark:bg-background p-[20px] rounded-[8px] z-[-1]")}/>
                                                )}
                                                {item.name}
                                            </motion.span>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </AnimateSharedLayout>
                </div>
                {this.props.account !== undefined && (
                    <div className="flex-shrink-0 flex border-t border-gray-200 dark:border-dark-700 p-4">
                        {/*<NavLink to="/dashboard/profile" className="flex-shrink-0 w-full group block">*/}
                            <div className="flex items-center">
                                <div>
                                    <img
                                        className="inline-block h-9 w-9 rounded-full border-2 p-1 bg-gray-400/30 dark:bg-dark-200/20 dark:border-dark-400"
                                        src={this.props.account.icon}
                                        alt=""
                                    />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-700 dark:text-gray-200 group-hover:text-gray-900 dark:group-hover:text-gray-300">{this.props.account.username}</p>
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400 group-hover:text-gray-700 dark:group-hover:text-gray-500 line-through">View profile</p>
                                </div>
                            </div>
                        {/*</NavLink>*/}
                    </div>
                )}
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params !== this.props.match.params) {
            this.setState({page: this.props.match.params.page});
        }
    }

    render() {
        if((!window.location.host.includes("lel.link") && (!window.location.host.includes("localhost:"))))
            return <Redirect to="/"/>;
        if(!this.props.account.loaded)
            return null;
        if(this.props.account.loaded && !this.props.account.loggedIn)
            return <Redirect to="/"/>;

        let pageParam = this.state.page || "overview";
        let page;

        switch(pageParam) {
            case 'overview':
                page = <DashboardOverview {...this.props} />;
                break;
            case 'subscription':
                page = <DashboardSubscription {...this.props} />;
                break;
            case 'links':
                page = <DashboardLinks {...this.props} />;
                break;
            case 'website':
                page = <DashboardWebsite {...this.props} />;
                break;
            case 'settings':
                page = <DashboardSettings {...this.props} />;
                break;
            default:
                page = <Dashboard404 {...this.props}  />;
                pageParam = "Error 404";
                break;
        }

        return (
            <div className="min-h-screen flex">
                <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={() => this.setState({sidebarOpen: false})}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 dark:bg-dark-500 bg-opacity-75 dark:bg-opacity-50" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-[255px] bg-white focus:outline-none">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2 group">
                                        <button
                                            type="button"
                                            className="mt-1.5 flex items-center justify-center h-8 w-8 bg-gray-200/50 dark:bg-dark-500/50 rounded-full focus:outline-none border-2 border-gray-200 group-hover:border-gray-400 dark:border-dark-200 dark:group-hover:border-dark-400"
                                            onClick={() => this.setState({sidebarOpen: false})}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-5 w-5 text-white group-hover:text-gray-300 dark:text-dark-100 dark:group-hover:text-dark-300" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {this.Header()}
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <div className="flex flex-col w-64">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        {this.Header()}
                    </div>
                </div>
                <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                    <div className="lg:hidden">
                        <div className="flex items-center justify-between bg-gray-50 dark:bg-darker border-b border-gray-200 dark:border-dark-700 px-4 py-1.5">
                            <div>
                                <img
                                    className="h-8 w-auto"
                                    src={logoNoText}
                                    alt=""
                                />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-dark-900 dark:text-dark-100 dark:hover:text-dark-300"
                                    onClick={() => this.setState({ sidebarOpen: true })}
                                >
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 relative z-0 flex overflow-hidden">
                        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last dark:bg-dark-900">
                            <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                                {pageParam !== "Error 404" && <h1 className="mb-5 text-2xl font-semibold font-biotif">{capitalize(pageParam)}</h1>}
                                {page}
                            </div>
                        </main>
                    </div>
                </div>

                <ThemeToggle/>
            </div>
        )
    }
}
