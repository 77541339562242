import { Fragment, Component, Suspense } from 'react';

import { CheckIcon, MinusIcon } from '@heroicons/react/outline';
import { Translation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const tiers = [
    // {
    //     name: 'Free',
    //     to: '/login',
    //     priceMonthly: 0,
    // },
    {
        name: 'Basic',
        to: '/dashboard/subscription',
        priceMonthly: 2,
    },
    {
        name: 'Essential',
        to: '/dashboard/subscription',
        priceMonthly: 5,
    },
    {
        name: 'Premium',
        to: '/dashboard/subscription',
        priceMonthly: 10,
    },
];

const sections = [
    {
        name: 'Features',
        features: [
            {
                name: 'aws',
                tiers: { Basic: true, Essential: true, Premium: true }
            },
            // {
            //     name: 'tags',
            //     tiers: { Essential: true, Premium: true }
            // },
            // {
            //     name: 'colors',
            //     tiers: { Essential: true, Premium: true }
            // },
            {
                name: 'cname',
                tiers: { Essential: true, Premium: true }
            },
            {
                name: 'branding',
                tiers: { Premium: true }
            },
            {
                name: 'new',
                tiers: { Premium: true }
            },
            // {
            //     name: 'page',
            //     tiers: { Premium: true }
            // },
            // {
            //     name: 'storage',
            //     tiers: { Basic: '1gb', Essential: '2gb', Premium: '5gb' }
            // }
        ],
    },
    {
        name: 'Support',
        features: [
            {
                name: 'premium',
                tiers: { Premium: true }
            },
        ],
    },
];

export default class Pricing extends Component {

    render() {
        return (
            <Suspense fallback="">
                <Translation>
                    {(t, { i18n }) => (
                        <>
                            <div>
                                <div className="max-w-2xl mx-auto px-4 py-16 sm:py-20 sm:px-6 lg:px-8 lg:max-w-7xl">
                                    <div className="px-0 sm:px-4 lg:px-0 lg:flex lg:justify-between lg:items-center">
                                        <div className="max-w-xl">
                                            <h2 className="text-4xl font-biotif font-semibold text-dark dark:text-white sm:text-5xl lg:text-6xl">
                                                {t('pricing.title')}
                                            </h2>
                                            <p className="mt-5 font-biotif font-medium leading-[24px] text-lg text-gray-600 dark:text-gray-300">
                                                {t('pricing.description')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Comparison table */}
                            <div className="max-w-2xl mx-3 sm:mx-auto bg-[#666666]/10 dark:bg-background/40 pt-4 pb-4 sm:pt-6 sm:pb-5 mb-8 sm:mb-16 sm:px-6 lg:max-w-7xl lg:px-8 rounded-md lg:rounded-xl">
                                {/* xs to lg */}
                                <div className="space-y-14 lg:hidden">
                                    {tiers.map((tier) => (
                                        <section key={tier.name}>
                                            <div className="px-4 mb-8">
                                                <h2 className="text-lg leading-6 dark:text-white font-medium text-gray-900">{t(`plans.${tier.name.toLowerCase()}.name`)}</h2>
                                                <p className="mt-4">
                                                    <span className="text-4xl font-semibold text-gray-900 dark:text-white">${tier.priceMonthly}</span>
                                                    <span className="text-base font-medium text-gray-500 dark:text-gray-300 font-itcavantgardestdmd">/mo</span>
                                                </p>
                                                <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">{t(`plans.${tier.name.toLowerCase()}.description`)}</p>
                                                <NavLink
                                                    to={tier.to}
                                                    className="mt-6 block w-full bg-gradient-to-r from-orange-500 to-lel-100 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-lel-500"
                                                >
                                                    {tier.priceMonthly === 0 ? "Login" : `Buy ${tier.name}`}
                                                </NavLink>
                                            </div>

                                            {sections.map((section) => (
                                                <table key={section.name} className="w-full">
                                                    <caption className="bg-gray-50/60 dark:bg-gray-50/10 border-t divide-border-300/40 dark:border-[#333] py-3 px-4 text-sm font-medium text-gray-900 dark:text-white text-left">
                                                        {section.name}
                                                    </caption>
                                                    <thead>
                                                    <tr>
                                                        <th className="sr-only" scope="col">
                                                            Feature
                                                        </th>
                                                        <th className="sr-only" scope="col">
                                                            Included
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-300/40 dark:divide-[#333]">
                                                    {section.features.map((feature) => (
                                                        <tr key={feature.name} className="border-t border-gray-300/40 dark:border-[#333]">
                                                            <th className="py-5 px-4 text-sm font-normal text-gray-500 dark:text-gray-300 text-left" scope="row">
                                                                {t(`plans.${section.name.toLowerCase()}.${feature.name}`)}
                                                            </th>
                                                            <td className="py-5 pr-4">
                                                                {typeof feature.tiers[tier.name] === 'string' ? (
                                                                    <span className="block text-sm text-gray-700 dark:text-gray-200 text-right">{feature.tiers[tier.name]}</span>
                                                                ) : (
                                                                    <>
                                                                        {feature.tiers[tier.name] === true ? (
                                                                            <CheckIcon className="ml-auto h-5 w-5 text-green-500" aria-hidden="true" />
                                                                        ) : (
                                                                            <MinusIcon className="ml-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                        )}

                                                                        <span className="sr-only">{feature.tiers[tier.name] === true ? 'Yes' : 'No'}</span>
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            ))}

                                            <div className="border-t border-gray-300/40 dark:border-[#333] px-4 pt-5">
                                                <NavLink
                                                    to={tier.to}
                                                    className="block w-full bg-gradient-to-r from-orange-500 to-lel-100 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-lel-500"
                                                >
                                                    {tier.priceMonthly === 0 ? "Login" : `Buy ${tier.name}`}
                                                </NavLink>
                                            </div>
                                        </section>
                                    ))}
                                </div>

                                {/* lg+ */}
                                <div className="hidden lg:block">
                                    <table className="w-full h-px table-fixed">
                                        <caption className="sr-only">Pricing plan comparison</caption>
                                        <thead>
                                        <tr>
                                            <th className="pb-4 pl-6 pr-6 text-sm font-medium text-gray-900 dark:text-gray-200 text-left" scope="col">
                                                <span className="sr-only">Feature by</span>
                                                <span>Plans</span>
                                            </th>
                                            {tiers.map((tier) => (
                                                <th
                                                    key={tier.name}
                                                    className="pb-4 px-6 text-lg leading-6 font-medium text-gray-900 dark:text-white dark:text-gray-200 text-left"
                                                    scope="col"
                                                >
                                                    {t(`plans.${tier.name.toLowerCase()}.name`)}
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody className="border-t border-gray-300/40 dark:border-[#333]">
                                        <tr>
                                            <th className="py-8 pl-6 pr-6 align-top text-sm font-medium text-gray-900 dark:text-white text-left" scope="row">
                                                Pricing
                                            </th>
                                            {tiers.map((tier) => (
                                                <td key={tier.name} className="h-full py-8 px-6 align-top">
                                                    <div className="h-full flex flex-col justify-between">
                                                        <div>
                                                            <p>
                                                                <span className="text-4xl font-semibold text-gray-900 dark:text-white">${tier.priceMonthly}</span>
                                                                <span className="text-base font-medium text-gray-500 dark:text-gray-300">/mo</span>
                                                            </p>
                                                            <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">{t(`plans.${tier.name.toLowerCase()}.description`)}</p>
                                                        </div>
                                                        <NavLink
                                                            to={tier.to}
                                                            className="mt-6 block w-full bg-gradient-to-r from-orange-500 to-lel-100 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-lel-500"
                                                        >
                                                            {tier.priceMonthly === 0 ? "Login" : `Buy ${tier.name}`}
                                                        </NavLink>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                        {sections.map((section) => (
                                            <Fragment key={section.name}>
                                                <tr>
                                                    <th
                                                        className="py-3 pl-6 bg-gray-50/60 dark:bg-gray-50/10 rounded-md text-sm font-medium text-gray-900 dark:text-white text-left"
                                                        colSpan={tiers.length + 1}
                                                        scope="colgroup"
                                                    >
                                                        {section.name}
                                                    </th>
                                                </tr>
                                                {section.features.map((feature, i) => (
                                                    <tr key={feature.name} className={i !== 0 ? "border-t border-gray-300/40 dark:border-[#333]" : ""}>
                                                        <th className="py-5 pl-6 pr-6 text-sm font-normal text-gray-500 dark:text-gray-300 text-left" scope="row">
                                                            {t(`plans.${section.name.toLowerCase()}.${feature.name}`)}
                                                        </th>
                                                        {tiers.map((tier) => (
                                                            <td key={tier.name} className="py-5 px-6">
                                                                {typeof feature.tiers[tier.name] === 'string' ? (
                                                                    <span className="block text-sm text-gray-700 dark:text-gray-200">{feature.tiers[tier.name]}</span>
                                                                ) : (
                                                                    <>
                                                                        {feature.tiers[tier.name] === true ? (
                                                                            <CheckIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                                                                        ) : (
                                                                            <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                        )}

                                                                        <span className="sr-only">
                                                                            {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                            <tr className="border-t border-gray-300/40 dark:border-[#333]">
                                                <th className="sr-only" scope="row">
                                                    Choose your plan
                                                </th>
                                                {tiers.map((tier) => (
                                                    <td key={tier.name} className="pt-5 px-6">
                                                        <NavLink
                                                            to={tier.to}
                                                            className="block w-full bg-gradient-to-r from-orange-500 to-lel-100 rounded-md shadow py-2 text-sm font-semibold text-white text-center hover:to-lel-500"
                                                        >
                                                            {tier.priceMonthly === 0 ? "Login" : `Buy ${tier.name}`}
                                                        </NavLink>
                                                    </td>
                                                ))}
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}
                </Translation>
            </Suspense>
        )
    }
}