import { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Footer extends Component {

    render() {
        const currentYear = new Date().getFullYear();

        return null;

        // return (
        //     <div className="mx-4 xxl:mx-0 text-black dark:text-white border-t dark:border-[#222]">
        //         <div className="max-w-7xl mx-auto py-2">
        //             &copy; 2020 - {currentYear} <NavLink to="/">LelLink</NavLink>. All rights reserved.
        //         </div>
        //     </div>
        // )
    }
}